import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { Container } from '../ui/containers';
import CountryCards from '../components/CountryCards';
import CountryBanner from '../assets/images/CountryBanner.jpg';
import { useDataLayerBuilder } from '@tymate/react-gtm';
import HeroHeader from '../components/HeroHeader';

export const query = graphql`
  query($language: String!) {
    allCountry(filter: { language: { eq: $language } }) {
      nodes {
        slug
        title
        pictures(limit: 10, randomize: true) {
          mediumUrl
        }
        picture {
          imagePath
          smallImagePath
        }
        cities {
          totalCount
        }
        hotels {
          totalCount
        }
      }
    }
  }
`;

const Destinations = ({
  data: {
    allCountry: { nodes: countries },
  },
}) => {
  const { t } = useTranslation();

  useDataLayerBuilder(() => ({
    pageType: 'countries',
  }));

  return (
    <Layout>
      <SEO title={t('pageTitle.countries')} />
      <HeroHeader
        title={t('header.countries')}
        image={CountryBanner}
      />
      <Container>
        <CountryCards countries={countries} />
      </Container>
    </Layout>
  );
};

export default Destinations;
